import * as React from "react";
import { useState, useEffect, useRef } from "react";
import WorldMap from "react-svg-worldmap";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { brown, lightBlue, lightGreen } from '@mui/material/colors';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import ArrowUpwardIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowDownwardIcon from '@mui/icons-material/KeyboardArrowDown';
import SortIcon from '@mui/icons-material/Sort';
import "./App.css";
import { data } from "./data2.js";
import RomanFont from './Macedonia.ttf'; // Importing the custom font

export default function App() {

  var population = [];
  var land = [];
  var density = [];

  for (let d of data) {
    population.push({
      country: d.code,
      value: d.population,
      name: d.name
    })
    land.push({
      country: d.code,
      value: d.land,
      name: d.name
    })
    density.push({
      country: d.code,
      value: d.density,
      name: d.name
    })
  }

  const theme = createTheme({
    palette: {
      primary: brown,
      secondary: lightBlue,
      error: lightGreen
    },
  });

  const [mapdata, setData] = useState([]);
  const [muitheme, setTheme] = useState("primary")
  const [color, setColor] = useState("#8d6e63")
  const [units, setUnits] = useState("sq km")
  const [sortByValue, setSortByValue] = useState("desc");
  const [showValueIcon, setShowValueIcon] = useState(false);
  const [sortByCountry, setSortByCountry] = useState("asc");
  const [showCountryIcon, setShowCountryIcon] = useState(false);
  const [columnWidth, setColumnWidth] = useState('auto');

  const tableContainerRef = useRef(null); // Ref for the TableContainer element

  useEffect(() => {
    const sortedData = [...land].sort((a, b) => b.value - a.value);
    setData(sortedData);
    setShowValueIcon(true);
    setColumnWidth('auto'); // Reset column width on dataset change
    scrollToTop(); // Scroll to the top of the table
  }, []); 

  useEffect(() => {
    const maxPopulation = Math.max(...population.map(item => item.value));
    setColumnWidth(`${maxPopulation.toString().length * 10}px`);
  }, [population]); // Calculate column width based on maximum population value

  const handleValueSortClick = () => {
    setSortByValue(sortByValue === "desc" ? "asc" : "desc");
    const sortedData = [...mapdata].sort((a, b) => sortByValue === "desc" ? b.value - a.value : a.value - b.value);
    setData(sortedData);
    setShowValueIcon(true);
    setShowCountryIcon(false);
    scrollToTop(); // Scroll to the top of the table
  }

  const handleCountrySortClick = () => {
    setSortByCountry(sortByCountry === "desc" ? "asc" : "desc");
    const sortedData = [...mapdata].sort((a, b) => sortByCountry === "desc" ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name));
    setData(sortedData);
    setShowCountryIcon(true);
    setShowValueIcon(false);
    scrollToTop(); // Scroll to the top of the table
  }

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const renderTable = () => {
    return (
      <TableContainer ref={tableContainerRef} className="table-container" component={Paper} sx={{ border: `2px solid ${theme.palette[muitheme].main}`, borderRadius: '5px' }}>
        <Table aria-label="simple table">
          <TableHead style={{ position: 'sticky', top: 0, zIndex: 1 }}>
            <TableRow>
              <TableCell style={{ backgroundColor: theme.palette[muitheme].main, color: 'white', cursor: 'pointer' }} onClick={handleCountrySortClick}>
                Country {showCountryIcon && (sortByCountry === "desc" ? <ArrowDownwardIcon sx={{ fontSize: 18 }} /> : <ArrowUpwardIcon sx={{ fontSize: 18 }} />)}
              </TableCell>
              <TableCell style={{ backgroundColor: theme.palette[muitheme].main, color: 'white', cursor: 'pointer', width: columnWidth }} onClick={handleValueSortClick}>
                {units} {showValueIcon && (sortByValue === "desc" ? <ArrowDownwardIcon sx={{ fontSize: 18 }} /> : <ArrowUpwardIcon sx={{ fontSize: 18 }} />)}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mapdata.map((item, index) => (
              <TableRow key={item.country} sx={index === 0 ? {} : {}}>
                <TableCell>{item.name}</TableCell>
                <TableCell>{numberWithCommas(item.value)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  // Function to scroll to the top
  const scrollToTop = () => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTo(0, 0); // Scroll to the top of the table
    }
  };

  return (
    <div className="App" sx={{ background: { color } }}>
      <Box
        sx={{
          position: 'absolute',
          top: '2px', // Adjusted top position
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: '1001',
          fontFamily: 'Roman, Georgia, serif', // Use the custom font
          fontSize: '10rem', // Increased font size
          textTransform: 'uppercase',
          letterSpacing: '4rem',
          color: theme.palette[muitheme].main ,
          opacity:0.85,
          userSelect: 'none',
          textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
        }}
      >
        Republics
      </Box>
      <Paper elevation={10} variant="elevation" className="main">
        <WorldMap
          color={color}
          valueSuffix={units}
          size="xxl"
          data={mapdata.map(item => ({ ...item, country: item.country }))} // Use names instead of codes for the map
        />
      </Paper>
      <div className="table-overlay">
        {renderTable()}
      </div>
      <ThemeProvider theme={theme}>
        <AppBar position="fixed" color={muitheme} sx={{ top: 'auto', bottom: 0 }}>
          <Toolbar sx={{ marginBottom: '10px', backgroundColor: theme.palette[muitheme].main }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
              <Button variant="contained" color="primary" onClick={() => {
                const sortedData = [...land].sort((a, b) => b.value - a.value);
                setData(sortedData);
                setTheme("primary")
                setColor("#8d6e63")
                setUnits("sq km")
                setShowValueIcon(false);
                setShowCountryIcon(false);
                scrollToTop();
              }}>Land</Button>
              <Box sx={{ marginLeft: '10px' }}>
                <Button variant="contained" color="secondary" onClick={() => {
                  const sortedData = [...population].sort((a, b) => b.value - a.value);
                  setData(sortedData);
                  setTheme("secondary")
                  setColor("#03a9f4")
                  setUnits("ppl")
                  setShowValueIcon(false);
                  setShowCountryIcon(false);
                  scrollToTop();
                }}>Population</Button>
              </Box>
              <Box sx={{ marginLeft: '10px' }}>
                <Button variant="contained" color="error" onClick={() => {
                  const sortedData = [...density].sort((a, b) => b.value - a.value);
                  setData(sortedData);
                  setTheme("error")
                  setColor("#33691e")
                  setUnits("ppl / sq km")
                  setShowValueIcon(false);
                  setShowCountryIcon(false);
                  scrollToTop();
                }}>Density</Button>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
      </ThemeProvider>

    </div>
  );
}
