import React from 'react'


export var data = [
  {
    "code": "af",
    "name": "afghanistan",
    "population": 42239854,
    "land": 652860,
    "density": 64
  },
  {
    "code": "al",
    "name": "albania",
    "population": 2832439,
    "land": 27400,
    "density": 103
  },
  {
    "code": "dz",
    "name": "algeria",
    "population": 45606480,
    "land": 2381740,
    "density": 19
  },
  {
    "code": "ar",
    "name": "argentina",
    "population": 45773884,
    "land": 2736690,
    "density": 16
  },
  {
    "code": "am",
    "name": "armenia",
    "population": 2777970,
    "land": 28470,
    "density": 97
  },
  {
    "code": "ao",
    "name": "angola",
    "population": 36684202,
    "land": 1246700,
    "density": 29
  },
  {
    "code": "at",
    "name": "austria",
    "population": 8958960,
    "land": 82409,
    "density": 108
  },
  {
    "code": "au",
    "name": "australia",
    "population": 26439111,
    "land": 7682300,
    "density": 3
  },
  {
    "code": "az",
    "name": "azerbaijan",
    "population": 10412651,
    "land": 82658,
    "density": 125
  },
  {
    "code": "bs",
    "name": "bahamas",
    "population": 412623,
    "land": 10010,
    "density": 41
  },
  {
    "code": "bd",
    "name": "bangladesh",
    "population": 172954319,
    "land": 130170,
    "density": 1328
  },
  {
    "code": "by",
    "name": "belarus",
    "population": 9498238,
    "land": 202910,
    "density": 46
  },
  {
    "code": "be",
    "name": "belgium",
    "population": 11686140,
    "land": 30280,
    "density": 385
  },
  {
    "code": "bz",
    "name": "belize",
    "population": 410825,
    "land": 22810,
    "density": 18
  },
  {
    "code": "bj",
    "name": "benin",
    "population": 13712828,
    "land": 112760,
    "density": 121
  },
  {
    "code": "bt",
    "name": "bhutan",
    "population": 787424,
    "land": 38117,
    "density": 20
  },
  {
    "code": "bo",
    "name": "bolivia",
    "population": 12388571,
    "land": 1083300,
    "density": 11
  },
  {
    "code": "ba",
    "name": "bosnia",
    "population": 3210847,
    "land": 51000,
    "density": 62
  },
  {
    "code": "bw",
    "name": "botswana",
    "population": 2675352,
    "land": 566730,
    "density": 4
  },
  {
    "code": "br",
    "name": "brazil",
    "population": 216422446,
    "land": 8358140,
    "density": 25
  },
  {
    "code": "bn",
    "name": "brunei",
    "population": 452524,
    "land": 5270,
    "density": 85
  },
  {
    "code": "bg",
    "name": "bulgaria",
    "population": 6687717,
    "land": 108560,
    "density": 61
  },
  {
    "code": "bf",
    "name": "burkina faso",
    "population": 23251485,
    "land": 273600,
    "density": 84
  },
  {
    "code": "bi",
    "name": "burundi",
    "population": 13238559,
    "land": 25680,
    "density": 515
  },
  {
    "code": "kh",
    "name": "cambodia",
    "population": 16944826,
    "land": 176520,
    "density": 95
  },
  {
    "code": "cm",
    "name": "cameroon",
    "population": 28647293,
    "land": 472710,
    "density": 60
  },
  {
    "code": "ca",
    "name": "canada",
    "population": 38781291,
    "land": 9093510,
    "density": 4
  },
  {
    "code": "cf",
    "name": "central africa",
    "population": 5742315,
    "land": 622980,
    "density": 9
  },
  {
    "code": "td",
    "name": "chad",
    "population": 18278568,
    "land": 1259200,
    "density": 14
  },
  {
    "code": "cl",
    "name": "chile",
    "population": 19629590,
    "land": 743532,
    "density": 26
  },
  {
    "code": "cn",
    "name": "china",
    "population": 1425671352,
    "land": 9388211,
    "density": 151
  },
  {
    "code": "co",
    "name": "colombia",
    "population": 52085168,
    "land": 1109500,
    "density": 46
  },
  {
    "code": "cr",
    "name": "costa rica",
    "population": 5212173,
    "land": 51060,
    "density": 102
  },
  {
    "code": "hr",
    "name": "croatia",
    "population": 4008617,
    "land": 55960,
    "density": 71
  },
  {
    "code": "cu",
    "name": "cuba",
    "population": 11194449,
    "land": 106440,
    "density": 105
  },
  {
    "code": "cy",
    "name": "cyprus",
    "population": 1260138,
    "land": 9240,
    "density": 136
  },
  {
    "code": "cz",
    "name": "czech",
    "population": 10495295,
    "land": 77240,
    "density": 135
  },
  {
    "code": "cd",
    "name": "congo",
    "population": 102262808,
    "land": 2267050,
    "density": 45
  },
  {
    "code": "dk",
    "name": "denmark",
    "population": 5910913,
    "land": 42430,
    "density": 139
  },
  {
    "code": "dj",
    "name": "djibouti",
    "population": 1136455,
    "land": 23180,
    "density": 49
  },
  {
    "code": "do",
    "name": "dominican",
    "population": 11332972,
    "land": 48320,
    "density": 234
  },
  {
    "code": "tl",
    "name": "timor leste",
    "population": 1360596,
    "land": 14870,
    "density": 91
  },
  {
    "code": "ec",
    "name": "ecuador",
    "population": 18190484,
    "land": 248360,
    "density": 73
  },
  {
    "code": "eg",
    "name": "egypt",
    "population": 112716598,
    "land": 995450,
    "density": 113
  },
  {
    "code": "sv",
    "name": "el salvador",
    "population": 6364943,
    "land": 20720,
    "density": 307
  },
  {
    "code": "gq",
    "name": "guinea",
    "population": 1714671,
    "land": 28050,
    "density": 61
  },
  {
    "code": "er",
    "name": "eritrea",
    "population": 3748901,
    "land": 101000,
    "density": 37
  },
  {
    "code": "ee",
    "name": "estonia",
    "population": 1322765,
    "land": 42390,
    "density": 31
  },
  {
    "code": "et",
    "name": "ethiopia",
    "population": 126527060,
    "land": 1000000,
    "density": 126
  },
  {
    "code": "fk",
    "name": "falkland",
    "population": 3791,
    "land": 12170,
    "density": 0
  },
  {
    "code": "fj",
    "name": "fiji",
    "population": 936375,
    "land": 18270,
    "density": 51
  },
  {
    "code": "fi",
    "name": "finland",
    "population": 5545475,
    "land": 303890,
    "density": 18
  },
  {
    "code": "fr",
    "name": "france",
    "population": 64756584,
    "land": 547557,
    "density": 118
  },
  {
    "code": "ga",
    "name": "gabon",
    "population": 2436566,
    "land": 257670,
    "density": 9
  },
  {
    "code": "gm",
    "name": "gambia",
    "population": 2773168,
    "land": 10120,
    "density": 274
  },
  {
    "code": "ge",
    "name": "georgia",
    "population": 3728282,
    "land": 69490,
    "density": 53
  },
  {
    "code": "de",
    "name": "germany",
    "population": 83294633,
    "land": 348560,
    "density": 238
  },
  {
    "code": "gh",
    "name": "ghana",
    "population": 34121985,
    "land": 227540,
    "density": 149
  },
  {
    "code": "gr",
    "name": "greece",
    "population": 10341277,
    "land": 128900,
    "density": 80
  },
  {
    "code": "gl",
    "name": "greenland",
    "population": 56643,
    "land": 410450,
    "density": 0
  },
  {
    "code": "gt",
    "name": "guatemala",
    "population": 18092026,
    "land": 107160,
    "density": 168
  },
  {
    "code": "gn",
    "name": "guinea",
    "population": 14190612,
    "land": 245720,
    "density": 57
  },
  {
    "code": "gw",
    "name": "guinea bissau",
    "population": 2150842,
    "land": 28120,
    "density": 76
  },
  {
    "code": "gy",
    "name": "guyana",
    "population": 813834,
    "land": 196850,
    "density": 4
  },
  {
    "code": "ht",
    "name": "haiti",
    "population": 11724763,
    "land": 27560,
    "density": 425
  },
  {
    "code": "hn",
    "name": "honduras",
    "population": 10593798,
    "land": 111890,
    "density": 94
  },
  {
    "code": "hu",
    "name": "hungary",
    "population": 10156239,
    "land": 90530,
    "density": 112
  },
  {
    "code": "is",
    "name": "iceland",
    "population": 375318,
    "land": 100250,
    "density": 3
  },
  {
    "code": "in",
    "name": "india",
    "population": 1428627663,
    "land": 2973190,
    "density": 480
  },
  {
    "code": "id",
    "name": "indonesia",
    "population": 277534122,
    "land": 1811570,
    "density": 153
  },
  {
    "code": "ir",
    "name": "iran",
    "population": 89172767,
    "land": 1628550,
    "density": 54
  },
  {
    "code": "iq",
    "name": "iraq",
    "population": 45504560,
    "land": 434320,
    "density": 104
  },
  {
    "code": "ie",
    "name": "ireland",
    "population": 5056935,
    "land": 68890,
    "density": 73
  },
  {
    "code": "il",
    "name": "israel",
    "population": 9174520,
    "land": 21640,
    "density": 423
  },
  {
    "code": "it",
    "name": "italy",
    "population": 58870762,
    "land": 294140,
    "density": 200
  },
  {
    "code": "ci",
    "name": "cote d ivore",
    "population": 28873034,
    "land": 318000,
    "density": 90
  },
  {
    "code": "jm",
    "name": "jamaica",
    "population": 2825544,
    "land": 10830,
    "density": 260
  },
  {
    "code": "jp",
    "name": "japan",
    "population": 123294513,
    "land": 364555,
    "density": 338
  },
  {
    "code": "jo",
    "name": "jordan",
    "population": 11337052,
    "land": 88780,
    "density": 127
  },
  {
    "code": "kz",
    "name": "kazakhstan",
    "population": 19606633,
    "land": 2699700,
    "density": 7
  },
  {
    "code": "ke",
    "name": "kenya",
    "population": 55100586,
    "land": 569140,
    "density": 96
  },
  {
    "code": "kw",
    "name": "kuwait",
    "population": 4310108,
    "land": 17820,
    "density": 241
  },
  {
    "code": "kg",
    "name": "kyrgyzstan",
    "population": 6735347,
    "land": 191800,
    "density": 35
  },
  {
    "code": "la",
    "name": "lao",
    "population": 7633779,
    "land": 230800,
    "density": 33
  },
  {
    "code": "lv",
    "name": "latvia",
    "population": 1830211,
    "land": 62200,
    "density": 29
  },
  {
    "code": "lb",
    "name": "lebanon",
    "population": 5353930,
    "land": 10230,
    "density": 523
  },
  {
    "code": "ls",
    "name": "lesotho",
    "population": 2330318,
    "land": 30360,
    "density": 76
  },
  {
    "code": "lr",
    "name": "liberia",
    "population": 5418377,
    "land": 96320,
    "density": 56
  },
  {
    "code": "ly",
    "name": "libya",
    "population": 6888388,
    "land": 1759540,
    "density": 3
  },
  {
    "code": "lt",
    "name": "lithuania",
    "population": 2718352,
    "land": 62674,
    "density": 43
  },
  {
    "code": "lu",
    "name": "luxemborg",
    "population": 654768,
    "land": 2590,
    "density": 252
  },
  {
    "code": "mk",
    "name": "macedonia",
    "population": 2085679,
    "land": 25220,
    "density": 82
  },
  {
    "code": "mg",
    "name": "madagascar",
    "population": 30325732,
    "land": 581795,
    "density": 52
  },
  {
    "code": "mw",
    "name": "malawi",
    "population": 20931751,
    "land": 94280,
    "density": 222
  },
  {
    "code": "my",
    "name": "malaysia",
    "population": 34308525,
    "land": 328550,
    "density": 104
  },
  {
    "code": "ml",
    "name": "mali",
    "population": 23293698,
    "land": 1220190,
    "density": 19
  },
  {
    "code": "mr",
    "name": "mauritania",
    "population": 4862989,
    "land": 1030700,
    "density": 4
  },
  {
    "code": "mx",
    "name": "mexico",
    "population": 128455567,
    "land": 1943950,
    "density": 66
  },
  {
    "code": "md",
    "name": "moldova",
    "population": 3435931,
    "land": 32850,
    "density": 104
  },
  {
    "code": "mn",
    "name": "mongolia",
    "population": 3447157,
    "land": 1553560,
    "density": 2
  },
  {
    "code": "me",
    "name": "montenegro",
    "population": 626485,
    "land": 13450,
    "density": 46
  },
  {
    "code": "ma",
    "name": "morocco",
    "population": 37840044,
    "land": 446300,
    "density": 84
  },
  {
    "code": "mz",
    "name": "mozambique",
    "population": 33897354,
    "land": 786380,
    "density": 43
  },
  {
    "code": "mm",
    "name": "myanmar",
    "population": 54577997,
    "land": 653290,
    "density": 83
  },
  {
    "code": "na",
    "name": "namibia",
    "population": 2604172,
    "land": 823290,
    "density": 3
  },
  {
    "code": "np",
    "name": "nepal",
    "population": 30896590,
    "land": 143350,
    "density": 215
  },
  {
    "code": "nl",
    "name": "netherlands",
    "population": 17618299,
    "land": 33720,
    "density": 522
  },
  {
    "code": "nc",
    "name": "new caledonia",
    "population": 292991,
    "land": 18280,
    "density": 16
  },
  {
    "code": "nz",
    "name": "new zealand",
    "population": 5228100,
    "land": 263310,
    "density": 19
  },
  {
    "code": "ni",
    "name": "nicaragua",
    "population": 7046310,
    "land": 120340,
    "density": 58
  },
  {
    "code": "ne",
    "name": "niger",
    "population": 27202843,
    "land": 1266700,
    "density": 21
  },
  {
    "code": "ng",
    "name": "nigeria",
    "population": 223804632,
    "land": 910770,
    "density": 245
  },
  {
    "code": "kp",
    "name": "nkorea",
    "population": 26160821,
    "land": 120410,
    "density": 217
  },
  {
    "code": "no",
    "name": "norway",
    "population": 5474360,
    "land": 365268,
    "density": 14
  },
  {
    "code": "om",
    "name": "oman",
    "population": 4644384,
    "land": 309500,
    "density": 15
  },
  {
    "code": "pk",
    "name": "pakistan",
    "population": 240485658,
    "land": 770880,
    "density": 311
  },
  {
    "code": "ps",
    "name": "palestine",
    "population": 5371230,
    "land": 6020,
    "density": 892
  },
  {
    "code": "pa",
    "name": "panama",
    "population": 4468087,
    "land": 74340,
    "density": 60
  },
  {
    "code": "pg",
    "name": "papa new guinea",
    "population": 10329931,
    "land": 452860,
    "density": 22
  },
  {
    "code": "py",
    "name": "paraguay",
    "population": 6861524,
    "land": 397300,
    "density": 17
  },
  {
    "code": "pe",
    "name": "peru",
    "population": 34352719,
    "land": 1280000,
    "density": 26
  },
  {
    "code": "ph",
    "name": "philippines",
    "population": 117337368,
    "land": 298170,
    "density": 393
  },
  {
    "code": "pl",
    "name": "poland",
    "population": 41026067,
    "land": 306230,
    "density": 133
  },
  {
    "code": "pt",
    "name": "portugal",
    "population": 10247605,
    "land": 91590,
    "density": 111
  },
  {
    "code": "pr",
    "name": "puerto rico",
    "population": 3260314,
    "land": 8870,
    "density": 367
  },
  {
    "code": "qa",
    "name": "qatar",
    "population": 2716391,
    "land": 11610,
    "density": 233
  },
  {
    "code": "cg",
    "name": "congo",
    "population": 6106869,
    "land": 341500,
    "density": 17
  },
  {
    "code": "ro",
    "name": "romania",
    "population": 19892812,
    "land": 230170,
    "density": 86
  },
  {
    "code": "ru",
    "name": "russia",
    "population": 144444359,
    "land": 16376870,
    "density": 8
  },
  {
    "code": "rw",
    "name": "rwanda",
    "population": 14094683,
    "land": 24670,
    "density": 571
  },
  {
    "code": "sa",
    "name": "saudi arabia",
    "population": 36947025,
    "land": 2149690,
    "density": 17
  },
  {
    "code": "sn",
    "name": "senegal",
    "population": 17763163,
    "land": 192530,
    "density": 92
  },
  {
    "code": "rs",
    "name": "serbia",
    "population": 7149077,
    "land": 87460,
    "density": 81
  },
  {
    "code": "sl",
    "name": "sierra leona",
    "population": 8791092,
    "land": 72180,
    "density": 121
  },
  {
    "code": "sk",
    "name": "slovakia",
    "population": 5795199,
    "land": 48088,
    "density": 120
  },
  {
    "code": "si",
    "name": "slovenia",
    "population": 2119675,
    "land": 20140,
    "density": 105
  },
  {
    "code": "sb",
    "name": "solomon islands",
    "population": 740424,
    "land": 27990,
    "density": 26
  },
  {
    "code": "so",
    "name": "somalia",
    "population": 18143378,
    "land": 627340,
    "density": 28
  },
  {
    "code": "za",
    "name": "south africa",
    "population": 60414495,
    "land": 1213090,
    "density": 49
  },
  {
    "code": "kr",
    "name": "skorea",
    "population": 51784059,
    "land": 97230,
    "density": 532
  },
  {
    "code": "ss",
    "name": "south sudan",
    "population": 11088796,
    "land": 610952,
    "density": 18
  },
  {
    "code": "es",
    "name": "spain",
    "population": 47519628,
    "land": 498800,
    "density": 95
  },
  {
    "code": "lk",
    "name": "sri lanka",
    "population": 21893579,
    "land": 62710,
    "density": 349
  },
  {
    "code": "sd",
    "name": "sudan",
    "population": 48109006,
    "land": 1765048,
    "density": 27
  },
  {
    "code": "sr",
    "name": "suriname",
    "population": 623236,
    "land": 156000,
    "density": 3
  },
  {
    "code": "sz",
    "name": "eswatini",
    "population": 1210822,
    "land": 17200,
    "density": 70
  },
  {
    "code": "se",
    "name": "sweden",
    "population": 10612086,
    "land": 410340,
    "density": 25
  },
  {
    "code": "ch",
    "name": "switzerland",
    "population": 8796669,
    "land": 39516,
    "density": 222
  },
  {
    "code": "sy",
    "name": "syria",
    "population": 23227014,
    "land": 183630,
    "density": 126
  },
  {
    "code": "tw",
    "name": "taiwan",
    "population": 23923276,
    "land": 35410,
    "density": 675
  },
  {
    "code": "tj",
    "name": "tajikistan",
    "population": 10143543,
    "land": 139960,
    "density": 72
  },
  {
    "code": "tz",
    "name": "tanzania",
    "population": 67438106,
    "land": 885800,
    "density": 76
  },
  {
    "code": "th",
    "name": "thailand",
    "population": 71801279,
    "land": 510890,
    "density": 140
  },
  {
    "code": "tg",
    "name": "togo",
    "population": 9053799,
    "land": 54390,
    "density": 166
  },
  {
    "code": "tt",
    "name": "trinidad",
    "population": 1534937,
    "land": 5130,
    "density": 299
  },
  {
    "code": "tn",
    "name": "tunisia",
    "population": 12459223,
    "land": 155360,
    "density": 80
  },
  {
    "code": "tr",
    "name": "turkey",
    "population": 85816199,
    "land": 769630,
    "density": 111
  },
  {
    "code": "tm",
    "name": "turkmenistan",
    "population": 6516100,
    "land": 469930,
    "density": 13
  },
  {
    "code": "ug",
    "name": "uganda",
    "population": 48582334,
    "land": 199810,
    "density": 243
  },
  {
    "code": "ua",
    "name": "ukraine",
    "population": 36744634,
    "land": 579320,
    "density": 63
  },
  {
    "code": "ae",
    "name": "uae",
    "population": 9516871,
    "land": 83600,
    "density": 113
  },
  {
    "code": "gb",
    "name": "uk",
    "population": 67736802,
    "land": 241930,
    "density": 279
  },
  {
    "code": "us",
    "name": "america",
    "population": 339996563,
    "land": 9147420,
    "density": 37
  },
  {
    "code": "uy",
    "name": "uruguay",
    "population": 3423108,
    "land": 175020,
    "density": 19
  },
  {
    "code": "uz",
    "name": "uzbekistan",
    "population": 35163944,
    "land": 425400,
    "density": 82
  },
  {
    "code": "vu",
    "name": "vanuatu",
    "population": 334506,
    "land": 12190,
    "density": 27
  },
  {
    "code": "ve",
    "name": "venezuela",
    "population": 28838499,
    "land": 882050,
    "density": 32
  },
  {
    "code": "eh",
    "name": "western sahara",
    "population": 587259,
    "land": 266000,
    "density": 2
  },
  {
    "code": "ye",
    "name": "yemen",
    "population": 34449825,
    "land": 527970,
    "density": 65
  },
  {
    "code": "zm",
    "name": "zambia",
    "population": 20569737,
    "land": 743390,
    "density": 27
  },
  {
    "code": "zw",
    "name": "zimbabwe",
    "population": 16665409,
    "land": 386850,
    "density": 43
  }
]